.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;

  &.night-section {
    display: flex;
    height: 100%;
    background: linear-gradient(180deg, #02284C 1.72%, #2583A3 23.71%, #B0C1CF 40.25%, #f9f9f9 74.92%);
  }

  .stars {
    padding-top: 2%;
    height: 247px;
    width: 100%;
    z-index: 0;

    @media (max-width: $small-screen-bp) {
      height: 150px;
    }
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .logo-subtext {
    font-family: $section-title-font;
    font-weight: 500;
    font-size: 24px;
    color: antiquewhite;
    max-width: 1080px;
    flex-direction: column;
    text-align: center;
    text-shadow: 2px 2px #000000;

    @media (max-width: 425px) {
      font-size: 18px;
      margin-top: 20px;
    } 
  }

  .festival-logo {
    max-width: 600px;
    -webkit-filter: drop-shadow(8px 8px 3px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(8px 8px 3px rgba(0, 0, 0, 0.5));
    
    &.desktop {
      display: none;

      @media (min-width: 550px) {
        display: block;
      }
    }

    &.mobile {
      display: none;

      @media (max-width: 550px) {
        display: block;
      }
    }
  }

  .clouds {
    margin-top: -5%;
    z-index: 0;
    width: 100%;
  }

  .intro-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1080px;
  }

  .full-width-image {
    width: 100%;
    background-size: cover;
  }

  .horizontal-text-line {
    word-spacing: 30px;
    white-space: nowrap;
    font-size: 52px;
    text-transform: uppercase;
    font-family: "Raleway";
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 20px;
    align-self: flex-start;

    @media (max-width: $small-screen-bp) {
      font-size: 32px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.content-element {
  margin-top: 30px;
  margin-bottom: 60px;
  padding: 20px;   
}

#intro-text {
  opacity: 0;
}

#img1 {
  background-image: url("../../assets/img_1.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 60vh;
}

.current-edition-section {
  padding: 100px 0px 200px;

  .middle-content {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
.inscrieri-text-trupa {
  margin-left: 10px;
  margin-right: 10px;
  flex-direction: column;
  text-align:left;
  max-width: 480px;
  text-indent: 0px;
}
.inscrieri-text-ateliere {
  margin-left: 10px;
  margin-right: 10px;
  flex-direction: column;
  text-align:left;
  max-width: 480px;
  text-indent: 0px;
}
.inscrieri-text-voluntari{
  margin-left: 10px;
  margin-right: 10px;
  flex-direction: column;
  text-align:left;
  max-width: 480px;
  text-indent: 0px;
}
.inscrieri-titlu-trupa {
  margin-left: 10px;
  margin-right: 5px;
}
.inscrieri-title-ateliere {
  margin-left: 10px;
  margin-right: 5px;
}
.inscrieri-titlu-voluntari {
  margin-left: 10px;
  margin-right: 5px;
}
/*
.motto {
  color: #d4af35;
  text-shadow: 0px 0px #bba14f;
}
*/
.Titlu-HartaZagaZagaSat {
  flex-direction: column;
  text-align:center;
}

.Trupe-Participante {
  flex-direction: column;
  text-align:center;
}

.Program-Festival {
  flex-direction: column;
  text-align:center;
}

.Titlu-Festival-de-Teatru-Ora-de-Teatru {
  flex-direction: column;
  text-align:center;
}

