.custom-navbar {
  position: fixed;
  width: 100%;
  top: -7rem;
  height: 7rem;
  padding: 10px;
  z-index: 999;
  background-color: white;
  -webkit-box-shadow: 0px 12px 18px -3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 12px 18px -3px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 12px 18px -3px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  transition: box-shadow 0.3s ease-in-out;

  .navbar-content {
    margin: 0 auto;
    width: 100rem;
    display: flex;

    .menu-section {
      margin-left: auto;
    }
  }

  &--hidden {
    opacity: 0;
    transform: translateY(-7rem);
    transition: all 0.3s ease-in-out;
  }

  &--visible {
    opacity: 1;
    transform: translateY(7rem);
    transition: transform 0.3s ease-in-out;
  }

  &--no-shadow {
    -webkit-box-shadow: 0px 12px 18px -3px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 12px 18px -3px rgba(0, 0, 0, 0);
    box-shadow: 0px 12px 18px -3px rgba(0, 0, 0, 0);
  }

  .logo {
    width: 8rem;
    max-height: 7rem;
  }
}
