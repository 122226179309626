.box {
  margin-left: 200px;
  margin-top: 600px;
  width: 100px;
  height: 100px;
}

.a {
  background-color: blue;
}

.b {
  background-color: rosybrown;
}

.c {
  background-color: saddlebrown;
}