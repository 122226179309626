.partner-logo {
    padding: 10px;
}

.partner-logo-small {
    @media (max-width: $medium-screen-bp) {
        max-width: 180px;
    }

    @media (max-width: $small-screen-bp) {
        max-width: 140px;
    }
}

/*Font pt parteneri*/
.responsive-partner
{
    margin-bottom: 30px;
    font-size: 23px;
    font-weight: 900;
    color: $main-color;
    text-align: center;

    @media (max-width: $small-screen-bp) {
        font-size: 33px;
    }
}