.team-section {
    position: fixed;
    z-index: 0;
    width: 100%;
    bottom: 0;
    background-color: $bg-theme-white;
    padding-bottom: 100px;
}

.social-logos-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    :not(:last-child) {
        margin-right: 40px;
    }
}

.festival-logo {
    margin-bottom: 60px;
    max-height: 240px;
    max-width: 25%;
}

.social-logo {
    width: 50px;
}
.social-logo-part{
    width:50px;
    max-width:80% ;
}