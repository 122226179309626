$animate: all 0.5s ease-in-out;

.menu {
  color: $main-color;

  a {
    cursor: pointer;
    margin-left: 8px;
    margin-right: 8px;
    transition: $animate;
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 0;
      height: 2px;
      margin: 2px 0 0;
      transition: $animate;
      opacity: 0;
      background-color: rgba($main-color, 0.4);
    }
  }

  a:hover,
  .active {
    -webkit-text-shadow: 0px 0px 0.5px $main-color;
    -moz-text-shadow: 0px 0px 0.5px $main-color;
    text-shadow: 0px 0px 0.5px $main-color;
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 2px;
      margin: 2px 0 0;
      transition: $animate;
      opacity: 1;
      background-color: rgba($main-color, 1);
    }
  }
}

.mobile-menu {
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &--hidden {
    opacity: 0;
  }
}
