#logo-nuclear {
  background-image: url("../../assets/logo_nuclear.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.sponsors-secondary-row {
  margin-top: 80px;
  padding-left: 40px;
  padding-right: 40px;
}

.clickable-logo {
  cursor: pointer;
}

.asociatie-logo {
  max-width: 300px;
  padding-bottom: 30px;
}

.organiser-text {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  font-size: 30px;
  text-align: center;

  @media (max-width: $small-screen-bp) {
      font-size: 24px;
  }
}