$main-color: #303336;
$section-title-font: "Raleway", sans-serif;
$small-screen-bp: 426px;
$medium-screen-bp: 960px;
$bg-theme-white: #f9f9f9;

.highlighted-content-wrapper {
    z-index: 10;
    position: relative;
    margin-bottom: 530px;
    

    @media (max-width: $small-screen-bp) {
        margin-bottom: 380px;
    }

    @media (min-width: $small-screen-bp) and (max-width: $medium-screen-bp) {
        margin-bottom: 510px;
    }

    @media (min-width: $medium-screen-bp) {
        margin-bottom: 480px;
    }
}

.white-backgrounded {
    background-color: white;
}

.logo-hall {
    max-width: 140px !important;
}

.custom-section {
    padding-top: 100px;

    &-title {
        margin-bottom: 50px !important;
    }

    @media (max-width: $small-screen-bp) {
        padding-top: 60px;
    }
}

.section-content-wrapper{
    max-width: 1080px;
}

.responsive-content {
    font-size: 21px;
    line-height: 33px;

    @media (max-width: $small-screen-bp) {
        font-size: 16px;
        line-height: 23px;
    }
}

.responsive-title {
    text-transform: uppercase;
    margin-bottom: 30px;
    font-size: 44px;
    font-weight: 900;
    color: $main-color;

    @media (max-width: $small-screen-bp) {
        font-size: 33px;
    }
}

.right-aligned {
    margin-left: auto;
}