button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  outline: none !important;
}

.cbtn-secondary {
  margin-left: 8px;
  margin-right: 8px;
}
